import map from 'lodash/map'
import { getMethod } from '../../service/client.service'
import * as postTransformer from '../../transformer/post'
import * as categoryTransformer from '../../transformer/category'
import * as userTransformer from '../../transformer/user'

export const getCategories = (query = {}, props) => {
    return getMethod('/api/categories', query)
    .then(response => response.json())
    .then(data => {
        const categories = map(data.data.categories, category => categoryTransformer.toState(category))
        return props.setState({ categories })
    })
}

export const getPosts = (query = {}, props) => {
    return getMethod('/api/posts', query)
    .then(response => response.json())
    .then(data => {
        const posts = map(data.data.posts, post => postTransformer.toState(post))
        return props.setState({ posts })
    })
}

export const getUsers = (query = {}, props) => {
    return getMethod('/api/users', query)
    .then(response => response.json())
    .then(data => {
        const users = map(data.data.users, user => userTransformer.toState(user))
        return props.setState({ users })
    })
}

export const getPost = (query = {}, props) => {
    return getMethod('/api/post', query)
    .then(response => response.json())
    .then(data => {
        const post = postTransformer.toState(data.data.post)
        return props.setState({ post })
    })
}

export const getUser = (query = {}, props) => {
    return getMethod('/api/user', query)
    .then(response => response.json())
    .then(data => {
        const user = userTransformer.toState(data.data.user)
        return props.setState({ user })
    })
}
