import moment from 'moment'
import { DATETIME_FULL_FORMAT_NO_SPLITTER } from '../../utilities/formats'

export const toApi = data => ({
    category_id: data.categoryId,
    content: data.content,
    created_at: moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATETIME_FULL_FORMAT_NO_SPLITTER)
        : null,
    id: data.id,
    slug: data.slug,
    thumbnail_image: data.thumbnailImage,
    title: data.title,
    updated_at: moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATETIME_FULL_FORMAT_NO_SPLITTER)
        : null,
    user_id: data.userId
})

export const toState = (data = {}) => ({
    category: data.category,
    categoryId: data.categoryId,
    content: data.content,
    createdAt: moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATETIME_FULL_FORMAT_NO_SPLITTER)
        : null,
    id: data.id,
    slug: data.slug,
    thumbnailImage: data.thumbnailImage,
    title: data.title,
    updatedAt: moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATETIME_FULL_FORMAT_NO_SPLITTER)
        : null,
    user: data.user,
    userId: data.userId
})
