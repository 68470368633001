import React, { Component } from 'react'
import truncate from 'lodash/truncate'

class RenderHTML extends Component {
    render () {
        const { content, isSummary } = this.props
        const displayedContent = isSummary ? truncate(content, {length: 500, 'separator': /.,? +/}) : content
        return (
            <section
                className='category__description'
                dangerouslySetInnerHTML={{ __html: displayedContent }}
            />
        )
    }
}

export default RenderHTML