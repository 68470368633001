import React, { Component } from 'react'
import get from 'lodash/get'
import { getPost, getUser } from '../../client/request/get/getRequest'

class About extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: {},
        }
    }

    componentDidMount = () => {
        const query = { id: 1 }
        getUser(query, this)
        const sluq = { sluq: 'selamat-ulang-tahun-kesayanganku' }
        getPost(sluq, this)
    }

    handleRedirect = url => e => {
        const { history } = this.props
        e.preventDefault()
        return history.push(url)
    }

    render() {
        const { user } = this.state
        const userName = get(user, 'name', '')
        const bio = get(user, 'bio', '')
        return (
            <div className='about'>
                <h1 className='about-header'>{userName}</h1>
                <p className='about-bio'>{bio}</p>
            </div>
        )
    }
}

export default About