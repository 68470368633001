import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import toUpper from 'lodash/toUpper'

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    handleRedirect = url => e => {
        const { history } = this.props
        e.preventDefault()
        return history.push(url)
    }

    handleOpenNewWindow = url => e => {
        e.preventDefault()
        return window.open(url)
    }

    render() {
        return (
            <div className='header'>
                <div className='header-child'>
                    <a href='/' className='header-child-menu' onClick={this.handleRedirect('/')}>{toUpper('Home')}</a>
                    <a href='/about' className='header-child-menu' onClick={this.handleRedirect('/about')}>{toUpper('About')}</a>
                </div>
                <div className='header-child'>
                    <div href='/' className='header-child-menu'>{toUpper('Fadly')}</div>
                    <div href='/' className='header-child-menu'>
                        <a href='https://github.com/fngadiyo' onClick={this.handleOpenNewWindow('https://github.com/fngadiyo')}>
                            <FontAwesomeIcon className='header-child-menu-icon' icon={faGithub} />
                        </a>
                        <a href='https://www.linkedin.com/in/fngadiyo/' onClick={this.handleOpenNewWindow('https://www.linkedin.com/in/fngadiyo/')}>
                            <FontAwesomeIcon className='header-child-menu-icon' icon={faLinkedinIn} />
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Header)