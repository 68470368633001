import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'

class Layout extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render () {
        return (
            <div className='container'>
                <Header />
                <section className='main'>{React.cloneElement(this.props.children)}</section>
                <div className='stub' />
                <Footer />
            </div>
        )
    }

}

export default withRouter(Layout)
