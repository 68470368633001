import React, { Component } from 'react'
import moment from 'moment'
import map from 'lodash/map'
import split from 'lodash/split'
import startCase from 'lodash/startCase'
import get from 'lodash/get'
import { DATETIME_FULL_FORMAT_NO_SPLITTER } from '../../utilities/formats'
import RenderHTML from '../basic-component/renderHTML/RenderHTML'
import { getPosts } from '../../client/request/get/getRequest'

class Category extends Component {
    constructor(props) {
        super(props)
        this.state = {
            posts: [],
        }
    }

    componentDidMount = () => {
        const { location } = this.props
        const categorySlug = split(location.pathname, '/')[2]
        const query = { categorySlug }

        getPosts(query, this)
    }

    handleRedirect = url => e => {
        const { history } = this.props
        e.preventDefault()
        return history.push(url)
    }

    render() {
        const { posts } = this.state
        const categoryName = get(posts, '0.category.name', '')
        const categorySlug = get(posts, '0.category.slug', '')
        return (
            <div className='category'>
                <a
                    className='category-button'
                    href={`/category/${categorySlug}`}
                    onClick={this.handleRedirect(`/category/${categorySlug}`)}
                >
                    {startCase(categoryName)}
                </a>
                {map(posts, post => {
                    return (
                        <a
                            className='category-posts'
                            href={`/post/${post.slug}`}
                            onClick={this.handleRedirect(`/post/${post.slug}`)}
                        >
                            <div className='content'>
                                <h1 className='content-title'>{post.title}</h1>
                                <h3 className='content-meta'>by {post.user.name} at {moment(post.createdAt).format(DATETIME_FULL_FORMAT_NO_SPLITTER)}</h3>
                                <RenderHTML content={post.content} isSummary={true} />
                            </div>
                        </a>
                    )
                })}
            </div>
        )
    }
}

export default Category