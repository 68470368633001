import React, { Component } from 'react'
import { authenticate } from '../../client/service/authRequest'

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            isLoginSuccessful: false,
        }
    }

    componentDidMount = () => {
    }
    
    componentDidUpdate = () => {
        const { history } = this.props
        const { isLoginSuccessful } = this.state
        if (isLoginSuccessful) {
            return history.push('/admin')
        }
    }

    handleInputEmail = (e) => {
        const email = e.target.value
        return this.setState({ email })
    }

    handleInputPassword = (e) => {
        const password = e.target.value
        return this.setState({ password })
    }

    handleLogin = (e) => {
        const { email, password } = this.state
        const query = { email, password }
        authenticate(query, this)
    }

    render() {
        return (
            <div className='login'>
                <input className='login-email' onChange={this.handleInputEmail} placeholder='Email' type='email'/>
                <input className='login-password' onChange={this.handleInputPassword} placeholder='Password' type='password'/>
                <button className='login-button' onClick={this.handleLogin}>Login</button>
            </div>
        )
    }
}

export default Login