import { postMethod } from '../../service/client.service'
import * as postTransformer from '../../transformer/post'

export const createPost = (data = {}, props) => {
    return postMethod('/api/post/create', {}, data)
    .then(response => response.json())
    .then(data => {
        const post = postTransformer.toState(data.data.post)
        return props.setState({ post })
    })
}
