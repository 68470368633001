import React, { Component } from "react";
import RichEditor from "../basic-component/richeditor/RichEditor";
import { validate } from "../../client/service/authRequest"
import { createPost } from "../../client/request/post/postRequest";
import slugify from "slugify";

class Admin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            postContent: '' ,
            postTitle: '',
            thumbnailImage: '',
            isTokenExpired: false
        }
    }

    componentDidMount = () => {
        validate(this)
    }

    componentDidUpdate = () => {
        const { isTokenExpired } = this.state
        const { history } = this.props
        if (isTokenExpired) {
            return history.push('/')
        }
    }

    handleChangeContent = (postContent) => {
        return this.setState({ postContent })
    }

    handleChangeThumbnailImage = (thumbnailImage) => {
        // const img = document.getElementsByTagName('img')
        return this.setState({ thumbnailImage })
    }

    handleChangeTitle = (e) => {
        const postTitle = e.target.value
        return this.setState({ postTitle })
    }

    handleSave = (e) => {
        const { postContent, postTitle, thumbnailImage } = this.state
        const data = {
            category_id: 1,
            content: postContent,
            slug: slugify(postContent),
            thumbnail_image: thumbnailImage,
            title: postTitle,
            user_id: 1
        }
        createPost(data, this)
    }

    render () {
        const { postContent, thumbnailImage } = this.state
        return (
            <div className='add-edit-post'>
                <input onChange={this.handleChangeTitle} placeholder='Title' type='text'/>
                <RichEditor
                    onChange={this.handleChangeContent}
                    placeholder='Write here'
                    value={postContent}
                />
                <RichEditor
                    onChange={this.handleChangeThumbnailImage}
                    placeholder='ThumbnailImage'
                    modules={{ toolbar: ['image'] }}
                    value={thumbnailImage}
                />
                <button
                    className='add-edit-post-button'
                    onClick={this.handleSave}
                >
                    Create
                </button>
            </div>
        )
    }
}

export default Admin