import qs from 'qs'
import { getAuthHeader, postAuthHeader, putAuthHeader, deleteAuthHeader } from './auth.service'

const stringify = qs.stringify

export const getMethod = (url, query = {}) => {
    const qs = stringify(query)
    return fetch(`${url}?${qs}`, getAuthHeader)
}
export const postMethod = (url, query = {}, data = {}) => {
    const body = JSON.stringify(data)
    const header = postAuthHeader
    header.body = body
    const qs = stringify(query)
    return fetch(`${url}?${qs}`, header)
}
export const putMethod = (url, query = {}) => {
    const qs = stringify(query)
    return fetch(`${url}?${qs}`, putAuthHeader)
}
export const deleteMethod = (url, query = {}) => {
    const qs = stringify(query)
    return fetch(`${url}?${qs}`, deleteAuthHeader)
}