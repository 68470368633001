import React, { PureComponent } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

class RichEditor extends PureComponent {
    render() {
        const { onChange, placeholder, value, modules } = this.props

        return (
            <ReactQuill
                modules={modules}
                onChange={onChange}
                placeholder={placeholder}
                theme='snow'
                value={value}
            />
        )
    }
}

RichEditor.defaultProps = {
    modules: {
        toolbar: [
          [{ header: [1, 2, 3, false] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link'],
          ['clean'],
          ['image']
        ],
      }
}

export default RichEditor