import moment from 'moment'
import { DATETIME_FULL_FORMAT_NO_SPLITTER } from '../../utilities/formats'

export const toApi = data => ({
    bio: data.bio,
    created_at: moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATETIME_FULL_FORMAT_NO_SPLITTER)
        : null,
    email: data.email,
    id: data.id,
    last_login: moment(data.lastLogin).isValid()
        ? moment(data.lastLogin).format(DATETIME_FULL_FORMAT_NO_SPLITTER)
        : null,
    name: data.name,
    password: data.password,
    updated_at: moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATETIME_FULL_FORMAT_NO_SPLITTER)
        : null,
    username: data.username
})

export const toState = (data = {}) => ({
    bio: data.bio,
    createdAt: moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATETIME_FULL_FORMAT_NO_SPLITTER)
        : null,
    email: data.email,
    id: data.id,
    lastLogin: moment(data.lastLogin).isValid()
        ? moment(data.lastLogin).format(DATETIME_FULL_FORMAT_NO_SPLITTER)
        : null,
    name: data.name,
    password: data.password,
    updatedAt: moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATETIME_FULL_FORMAT_NO_SPLITTER)
        : null,
    username: data.username
})
