const bearerToken = window.localStorage.getItem('authToken')
export const getAuthHeader = {
    method: 'GET',
    withCredentials: true,
    credentials: 'include',
    headers: {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
    }
}
export const postAuthHeader = {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
    }
}
export const putAuthHeader = {
    method: 'PUT',
    withCredentials: true,
    credentials: 'include',
    headers: {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
    }
}
export const deleteAuthHeader = {
    method: 'DELETE',
    withCredentials: true,
    credentials: 'include',
    headers: {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
    }
}