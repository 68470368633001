import React, { Component } from 'react'

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
    const date = new Date()
    const year = date.getFullYear()
        return (
            <div className='footer'>
                <strong>
                 &copy; Fadly {year}
                </strong>
            </div>
        )
    }
}

export default Footer