import React, { Component } from 'react'
import moment from 'moment'
import split from 'lodash/split'
import get from 'lodash/get'
import startCase from 'lodash/startCase'
import { DATETIME_FULL_FORMAT_NO_SPLITTER } from '../../utilities/formats'
import RenderHTML from '../basic-component/renderHTML/RenderHTML'
import { getPost, getUser } from '../../client/request/get/getRequest'

class Post extends Component {
    constructor(props) {
        super(props)
        this.state = {
            post: {},
        }
    }

    componentDidMount = () => {
        const { location } = this.props
        const slug = split(location.pathname, '/')[2]
        const query = { slug }

        getPost(query, this)
        getUser({ id: 1 }, this)
    }

    handleRedirect = url => e => {
        const { history } = this.props
        e.preventDefault()
        return history.push(url)
    }

    render() {
        const { post } = this.state
        const title = get(post, 'title', '')
        const userName = get(post, 'user.name', '')
        const createdAt = get(post, 'createdAt', '')
        const content = get(post, 'content', '')
        const categoryName = get(post, 'category.name', '')
        const categorySlug = get(post, 'category.slug', '')
        return (
            <div className='post'>
                <div className='content'>
                    <h1 className='content-title'>{title}</h1>
                    <h3 className='content-meta'>by {userName} at {moment(createdAt).format(DATETIME_FULL_FORMAT_NO_SPLITTER)}</h3>
                    <RenderHTML content={content} />
                    <a
                        className='category-button'
                        href={`/category/${categorySlug}`}
                        onClick={this.handleRedirect(`/category/${categorySlug}`)}
                    >
                        {startCase(categoryName)}
                    </a>
                </div>
            </div>
        )
    }
}

export default Post