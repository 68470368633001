import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import About from './components/about/About'
import Admin from './components/admin/Admin'
import Category from './components/category/Category'
import Home from './components/home/Home'
import Layout from './Layout'
import Login from './components/login/Login'
import Post from './components/post/Post'

import './layout.scss'

const LayoutContainer = () => (
    <Layout>
        <Switch>
            <Route component={Home} exact path='/' />
            <Route component={About} path='/about' />
            <Route component={Admin} path='/admin' />
            <Route component={Category} path='/category/:slug' />
            <Route component={Login} path='/login' />
            <Route component={Post} path='/post/:slug' />
        </Switch>
    </Layout>
)

const DocumentAndLayoutContainer = () => (
    <Switch>
        <Route component={LayoutContainer} path='/' />
    </Switch>
)

const RouteHandler = () => (
    <BrowserRouter>
        <Route component={DocumentAndLayoutContainer} path='/' />
    </BrowserRouter>
)

export default RouteHandler
