export const DATE_FORMAT = 'DD-MM-YYYY'

export const YEAR_DATE_FORMAT = 'YYYY-MM-DD'

export const YEAR_MONTH_FORMAT = 'MMMM YYYY'

export const DATE_FULL_FORMAT_WITHGAP = 'DD - MMMM - YYYY'

export const DATE_FULL_FORMAT_NO_SPLITTER = 'DD MMMM YYYY'
export const DATETIME_FULL_FORMAT_NO_SPLITTER = 'DD MMMM YYYY HH:mm'

export const DATETIME_FULL_FORMAT_WITHGAP = 'DD - MMMM - YYYY HH:mm'
export const YEAR_FORMAT = 'YYYY'

export const DAY_STRING_FORMAT = 'dddd'

export const TIME_FORMAT = 'HH:mm'

export const HOUR_FORMAT = 'HH'

export const TIME_FORMAT_12_HOURS = 'h:mm A'

export const DATETIME_FORMAT = 'DD-MM-YYYY HH:mm'

export const DATETIME_12HOURS_FORMAT = 'DD-MM-YYYY h:mm A'

export const YEAR_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'

export const DETAILED_DAY_DATE_FORMAT = 'ddd, Do MMMM YYYY'

export const DETAILED_DAY_DATETIME_FORMAT = 'ddd, Do MMMM YYYY HH:mm'

export const VALID_IMAGE_EXTENSIONS = 'image/jpeg, image/png'

export const VALID_PROOF_EXTENSIONS = 'image/jpeg, image/png, application/pdf'

export const VALID_FILE_EXTENSIONS = 'application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
