import get from 'lodash/get'
import { getMethod, postMethod } from './client.service'

export const authenticate = (query = {}, props) => {
    return postMethod('/api/authenticate', query)
    .then(response => response.json())
    .then(data => {
        const authToken = get(data, 'data.token', '')
        props.setState({
            isLoginSuccessful: true
        })
        return localStorage.setItem('authToken', authToken)
    })
}

export const validate = (props) => {
    return getMethod('/api/validate')
    .then(response => response.json())
    .then(data => {
        const { status } = data
        if (status === 200) {
            return
        }
        props.setState({
            isTokenExpired: true
        })
        return window.localStorage.removeItem('authToken')
    })
}

