import React, { Component } from 'react'
import moment from 'moment'
import map from 'lodash/map'
import { DATETIME_FULL_FORMAT_NO_SPLITTER } from '../../utilities/formats'
import RenderHTML from '../basic-component/renderHTML/RenderHTML'
import { getPosts } from '../../client/request/get/getRequest'

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            posts: []
        }
    }

    componentDidMount = () => {
        getPosts({}, this)
    }

    handleRedirect = url => e => {
        const { history } = this.props
        e.preventDefault()
        return history.push(url)
    }

    render() {
        const { posts } = this.state
        return (
            <div className='home'>
                {map(posts, post => {
                    return (
                        <a
                            className='home-posts'
                            href={`/post/${post.slug}`}
                            onClick={this.handleRedirect(`/post/${post.slug}`)}
                            key={post.slug}
                        >
                            <div className='content'>
                                <h1 className='content-title'>{post.title}</h1>
                                <hr/>
                                <h3 className='content-meta'>by {post.user.name} at {moment(post.createdAt).format(DATETIME_FULL_FORMAT_NO_SPLITTER)}</h3>
                                <RenderHTML content={post.content} isSummary={true} />
                            </div>
                        </a>
                    )
                })}
            </div>
        )
    }
}

export default Home